import React from 'react';
import { graphql } from 'gatsby';
import withPreview from '../utils/with-preview';
import PageGenericTemplate from './pageGenericTemplate';

const PageTemplateEmbedded = ({ data: { liferayPageGeneric }, location }) => {
  return (
    <PageGenericTemplate
      liferayPageGeneric={liferayPageGeneric}
      location={location}
      isEmbedded={true}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    liferayPageGeneric(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
        langCode
        taxonomyCategoryNames
      }
      pageBody {
        content {
          anchorBefore {
            value
          }
          anchorTopPageText {
            value
          }
          modules {
            value {
              __typename
              ...QueryFastQuote
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryLateralSticker
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFasciaCardImg
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryVideo
              ...QueryHero
              ...QueryHeroLanding
              ...QueryShortlink
              ...QueryFasciaCardEtichettaCircolare
              ...QueryPhotogallery
              ...QueryCardsIconeTestoFooter
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTestoIntroduttivo
              ...QueryBannerImmagineCircolare
              ...QueryAccordion
              ...QueryScaricaApp
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryFasciaBlog
              ...QueryPhoneBoxes
              ...QueryLandingForm
              ...QueryAssetList
            }
          }
          anchoredModules {
            value {
              __typename
              ...QueryAccordion
              ...QueryAgencyLocator
              ...QueryBanner
              ...QueryBannerImmagineCircolare
              ...QueryCardsIconeTestoFooter
              ...QueryFasciaCardEtichettaCircolare
              ...QueryFasciaCardImg
              ...QueryFasciaIcone
              ...QueryFasciaIconeTesto
              ...QueryFasciaInformativaECTA
              ...QueryFasciaProdotto
              ...QueryFastQuote
              ...QueryHero
              ...QueryLateralSticker
              ...QueryPhotogallery
              ...QueryScaricaApp
              ...QueryShortlink
              ...QueryTestoIntroduttivo
              ...QueryVideo
              ...QueryVideoGallery
              ...QueryVisoreSliderImmagini
              ...QueryTimeline
              ...QueryLinkDocumentList
              ...QueryFasciaTab
              ...QueryFolderTree
              ...QueryFolderTreeAlphabetical
              ...QueryContenutoHtml
              ...QueryFasciaBlog
              ...QueryPhoneBoxes
              ...QueryLandingForm
              ...QueryAssetList
            }
          }
        }
      }
      pageTitle {
        value
      }
      breadcrumbs {
        ... on LiferayPageGeneric {
          pageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailProduct {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailBlog {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailIniziative {
          detailPageTitle {
            value
          }
          link
        }
      }
      customField {
        content {
          disableLinkLogo {
            value
          }
          pageType {
            value
          }
          showChangeLanguageButton {
            value
          }
          hideSocialButton {
            value
          }
          showBreadcrumb {
            value
            content {
              showHomePage {
                value
              }
            }
          }
          showNavigationMenu {
            value
            content {
              ctaLabel {
                value
              }
              ctaLink {
                value
              }
              showBackButton {
                value
                content {
                  labelBackButton {
                    value
                  }
                }
              }
            }
          }
          scripts {
            value
          }
        }
      }
      seo {
        value {
          ... on LiferaySubmoduleSeo {
            ...QuerySeo
          }
        }
      }
      link
      linkLangMap
      lateralStickerAnchor
    }
  }
`;

export default withPreview(PageTemplateEmbedded, {
  subField: 'liferayPageGeneric',
  fixed: true,
});
