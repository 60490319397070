import React, { useState, useMemo } from 'react';
import Layout from '../organisms/layout';
import Seo from '../organisms/seo';
import { getSEOValue } from '../utils/functions';
import Breadcrumb from '../organisms/breadcrumb/Breadcrumb';
import MenuStickySinglePage from '../molecules/menu-sticky-single-page/MenuStickySinglePage';
import useWithCategoryAttributes from '../utils/hooks/useWithCategoryAttributes';
import {
  MapAnchoredModules,
  MapNonAnchoredModules,
} from '../molecules/modules-helpers/ModulesHelpers';
import { generateMenuAnchors } from '../utils/anchorHelpers';

const PageGenericTemplate = ({
  liferayPageGeneric,
  location,
  isEmbedded,
  bannerTop,
}) => {
  const [activeModule, setActiveModule] = useState(null);
  const categories = useWithCategoryAttributes(
    liferayPageGeneric?.liferayFields?.taxonomyCategoryNames
  );
  const pageSettings = {
    pageType: liferayPageGeneric.customField?.content?.pageType?.value[0],
    showChangeLanguageButton:
      liferayPageGeneric.customField?.content?.showChangeLanguageButton?.value,
    disableLinkLogo:
      liferayPageGeneric.customField?.content?.disableLinkLogo?.value,
    linkLangMap: liferayPageGeneric?.linkLangMap,
    langCode: liferayPageGeneric.liferayFields?.langCode,
    hideSocialButton:
      liferayPageGeneric.customField?.content?.hideSocialButton?.value,
    showBreadcrumb:
      liferayPageGeneric.customField?.content?.showBreadcrumb?.value,
    showHomePage:
      liferayPageGeneric.customField?.content?.showBreadcrumb?.content
        ?.showHomePage?.value,
    lateralStickerAnchor: liferayPageGeneric?.lateralStickerAnchor,
    showNavigationMenu:
      liferayPageGeneric.customField?.content?.showNavigationMenu?.value,
    scripts: liferayPageGeneric?.customField?.content?.scripts,
  };

  const pageSEOSettings = getSEOValue(liferayPageGeneric.seo);

  const concatenateWithComma = (stringArray) => {
    let txt = '';
    if (stringArray !== undefined) {
      for (let i = 0; i < stringArray.length; i++) {
        if (i > 0) {
          txt += ', ';
        }
        txt += stringArray[i].value;
      }
    }
    return txt;
  };

  const buttonCtaLink =
    liferayPageGeneric.customField?.content?.showNavigationMenu?.content
      ?.ctaLink?.value;
  const buttonCtaLabel =
    liferayPageGeneric.customField?.content?.showNavigationMenu?.content
      ?.ctaLabel?.value;

  const showButtonBack =
    liferayPageGeneric.customField?.content?.showNavigationMenu?.content
      ?.showBackButton?.value;
  const labelButtonBack =
    liferayPageGeneric.customField?.content?.showNavigationMenu?.content
      ?.showBackButton?.content?.labelBackButton?.value;

  const anchoredModules =
    liferayPageGeneric?.pageBody?.content?.anchoredModules?.filter(
      (module) => !!module.value
    ) || [];

  const notAnchoredModules =
    liferayPageGeneric?.pageBody?.content?.modules?.filter(
      (module) => !!module.value
    ) || [];

  const menuAnchors =
    pageSettings.showNavigationMenu && generateMenuAnchors(anchoredModules);

  const breadcrumbs = useMemo(() => {
    const homePage = { label: 'Home', link: '/' };
    const breadcrumbsTemp = liferayPageGeneric.breadcrumbs
      .filter((page) => page.link !== '')
      .map((page) => ({
        label: page.pageTitle?.value || page.detailPageTitle?.value,
        link: page.link,
      }))
      .concat({
        label: liferayPageGeneric.pageTitle?.value,
      });
    if (pageSettings.showHomePage) {
      breadcrumbsTemp.unshift(homePage);
    }
    return breadcrumbsTemp;
  }, [liferayPageGeneric, pageSettings.showHomePage]);

  const labelPenultimateElement = breadcrumbs.slice(-2, -1)[0]?.label;
  const linkPenultimateElement = breadcrumbs.slice(-2, -1)[0]?.link;

  const metaRobotsEmbedded = { value: ['none'] };

  return (
    <Layout
      pageSettings={pageSettings}
      isEmbedded={isEmbedded}
      bannerTop={bannerTop}
    >
      <Seo
        title={pageSEOSettings.ogTitle || liferayPageGeneric.pageTitle.value}
        description={pageSEOSettings.ogDescription}
        image={pageSEOSettings.ogImage}
        robots={
          isEmbedded
            ? metaRobotsEmbedded?.value?.[0]
            : concatenateWithComma(pageSEOSettings.metaRobots)
        }
        link={pageSEOSettings.ogUrl || liferayPageGeneric.link}
        type={pageSEOSettings.ogType}
        scripts={pageSettings.scripts}
        jsonLd={pageSEOSettings.structuredDataJsonLd}
      />
      <div className='container' data-search-type='page' {...categories}>
        {!!notAnchoredModules?.length &&
          pageSettings.showBreadcrumb &&
          !isEmbedded &&
          breadcrumbs.length && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
        <MapNonAnchoredModules
          nonAnchoredModules={notAnchoredModules}
          setActiveModule={setActiveModule}
          isEmbedded={isEmbedded}
        />
        {pageSettings.showNavigationMenu && (
          <MenuStickySinglePage
            anchors={menuAnchors}
            activeAnchor={activeModule}
            buttonCtaLink={buttonCtaLink}
            buttonCtaText={buttonCtaLabel}
            showButtonBack={showButtonBack}
            labelButtonBack={labelButtonBack + ' ' + labelPenultimateElement}
            linkButtonBack={linkPenultimateElement}
            hasModulesWithoutAnchorAbove={!!notAnchoredModules.length}
          />
        )}
        {!notAnchoredModules?.length &&
          pageSettings.showBreadcrumb &&
          !isEmbedded &&
          breadcrumbs.length && <Breadcrumb items={breadcrumbs}></Breadcrumb>}
        <MapAnchoredModules
          anchoredModules={anchoredModules}
          setActiveModule={setActiveModule}
          showNavigationMenu={pageSettings.showNavigationMenu}
          isEmbedded={isEmbedded}
        />
      </div>
    </Layout>
  );
};

export default PageGenericTemplate;
